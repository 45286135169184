const firebaseConfig = {
  projectId: "lions-head-b5688",
  appId: "1:116628634156:web:40075b66a4445e96d2af89",
  databaseURL:
    "https://lions-head-b5688-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "lions-head-b5688.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyAr0jsLPEIzqaJgtiUspG-BIS-ysqvviMQ",
  authDomain: "lions-head-b5688.firebaseapp.com",
  messagingSenderId: "116628634156",
  measurementId: "G-JRTGTH2JK6",
};

export default firebaseConfig;
